import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { getQueryString } from "./helpers/props";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import "./scss/fonts.scss";
import "./scss/styles.scss";

// containers
const Home = React.lazy(() => import("./containers/home"));
const Profile = React.lazy(() => import("./containers/profile"));
const Product = React.lazy(() => import("./containers/product"));
const LoanSubmission = React.lazy(() => import("./containers/loan-submission"));
const LoanAcceptance = React.lazy(() => import("./containers/loan-acceptance"));

const ExternalRedirect = React.lazy(() => import("./containers/external-redirect"));

const NotFound = React.lazy(() => import("./containers/static/404"));

function App() {
    return (
        <Router>
            <React.Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/u/:code" component={Home} />
                    <Route exact path="/profile" component={Profile} />
                    <Route exact path="/apply" component={Product} />
                    <Route exact path="/submit" component={LoanSubmission} />
                    <Route exact path="/accept" component={LoanAcceptance} />
                    <Route exact path="/accept/:type" render={(p) => <div>Accept by type</div>} />
                    <Route exact path="/complete" render={(p) => <div>Complete</div>} />
                    <Route
                        exact
                        path="/redirect"
                        render={(props) => {
                            const { to = false } = getQueryString(props);

                            return to ? <ExternalRedirect to={to} /> : <Redirect to="/" />;
                        }}
                    />

                    <Route component={NotFound} />
                </Switch>
                <ToastContainer />
            </React.Suspense>
        </Router>
    );
}

export default App;

// Props Helpers

export function getReferer(componentProps) {
    const {
        location: { state = false },
    } = componentProps;
    let { referer = false } = state || {};

    if (!referer) {
        referer = window.location.href.includes(window.document.referrer) === false ? window.document.referrer : false;
    }

    return referer;
}

export function getPathParams(componentProps, pathName = false) {
    if (componentProps && componentProps.match) {
        const { params, path } = componentProps.match;
        params["__path"] = path;
        return pathName ? params[pathName] : params;
    }
    return;
}

export function getLocationState(componentProps, stateName = false) {
    if (componentProps && componentProps.location) {
        const { state = {} } = componentProps.location;
        return stateName ? state[stateName] : state;
    }
    return;
}

export function getLocationPath(componentProps) {
    if (componentProps && componentProps.location) {
        const { pathname } = componentProps.location;
        return pathname;
    }
    return;
}

export function getQueryString(componentProps, param = false) {
    if (componentProps && componentProps.location) {
        const { search } = componentProps.location;

        if (search && search !== "") {
            const paramItems = new URLSearchParams(search);
            const params = {};

            for (const paramItem of paramItems) {
                const [param, value] = paramItem;

                if (/\[\]$/.test(param)) {
                    const paramArrayName = param.replace(/\[\]$/, "");

                    if (!params[paramArrayName]) {
                        params[paramArrayName] = [];
                    }
                    params[paramArrayName].push(value);
                } else {
                    params[param] = value;
                }
            }

            return param ? params[param] : params;
        }
    }
    return {};
}
